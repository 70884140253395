import { Stack, Typography, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { useRouter } from 'next/router'
import React from 'react'

import Button from 'src/common/components/button/Button'
import { COLOR_THEME } from 'src/common/constants'
import { rem } from 'src/common/utils/css'
import CarouselView from 'src/components/card-carousel/CarouselView'
import VerticalStackView from 'src/components/card-carousel/VerticalStackView'
import ArrowRight from 'src/components/icons/ArrowRight'
import theme, { SECONDARY_COLOR } from 'src/styles/theme'
import GridView from 'src/components/card-carousel/GridView'
import CardTrail from 'src/common/components/card/trail/Trail'
import CardPlace from 'src/common/components/card/place/Place'
import CardBlogAndNews from 'src/common/components/card/blog-and-news/BlogAndNews'
import CardItinerary from 'src/common/components/card/itinerary/Itinerary'
import { CARD_ALIGNMENT } from 'src/components/card-carousel/constants'
import { CARD_TYPE } from 'src/common/components/card/constants'
import CardEvent from 'src/common/components/card/event/Event'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { TextButtonExternalLink } from 'src/common/components/externalSiteIcon'

const useStyles = makeStyles()((defaultTheme, props) => {
  const { numberOfCards, alignment, background } = props

  let backgroundColor

  switch (background) {
    case COLOR_THEME.BACKGROUND:
      backgroundColor = theme.palette.presidio.color.LIGHT_BACKGROUND
      break
    case COLOR_THEME.THE_BAKER_BEACH:
      backgroundColor = theme.palette.presidio.color.BAKER_BEACH_WHITE
      break
    default:
      backgroundColor = SECONDARY_COLOR.LIGHT['40']
      break
  }

  return {
    container: {
      backgroundColor: backgroundColor,
      padding: `${rem(40)} ${rem(24)}`,
      [theme.breakpoints.up('md')]: {
        padding: `${rem(64)} ${rem(40)}`,
      },
      [theme.breakpoints.up('lg')]: {
        padding: `${rem(64)} ${
          numberOfCards === 1 || alignment === CARD_ALIGNMENT.HORIZONTAL
            ? rem(120)
            : rem(40)
        }`,
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem(64)} ${rem(156)}`,
      },
      overflowX: 'clip',
      gap: rem(40),
      display: 'flex',
      flexDirection: 'column',
    },
    frame1: {
      display: 'flex',
      gap: rem(22),
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
      },
    },
    heading: {
      ...theme.typography.h2,
      color: theme.palette.primary.dark,
    },
    subheading: {
      ...theme.typography.body.default,
      color: theme.palette.presidio.color.MILD_GRAY,
    },
    button: {
      flexShrink: 0,
    },
  }
})

export default function CardCarousel(props) {
  const { data } = props
  if (!data) return null

  const { card_carousel, section_id } = data
  if (!card_carousel) return null

  const {
    heading,
    subheading,
    cta,
    card_alignment,
    select_card_tags,
    carousel_on_mobile,
    background_color,
    select_content_type,
    place_type,
  } = card_carousel

  const { classes } = useStyles({
    numberOfCards: select_card_tags?.length || 0,
    alignment: card_alignment,
    background: background_color,
  })
  const router = useRouter()

  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'))
  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))
  const xlUp = useMediaQuery(() => theme.breakpoints.up('xl'))

  if (select_card_tags < 1) return null

  function cardActionClickHandler(action) {
    if (action.target === '_blank') {
      window.open(action.url, '_blank')
    } else {
      router.push(action.url)
    }
  }

  const layoutCard = React.useMemo(() => {
    switch (select_content_type) {
      case CARD_TYPE.PLACES: {
        if (place_type === CARD_TYPE.TRAIL) {
          return <CardTrail />
        }
        return <CardPlace />
      }

      case CARD_TYPE.POST:
        return <CardBlogAndNews />

      case CARD_TYPE.ITINERARIES:
        return <CardItinerary />

      case CARD_TYPE.PRESS:
        return <CardBlogAndNews />

      case CARD_TYPE.EVENT:
        return <CardEvent />

      default:
        return <CardPlace />
    }
  }, [select_content_type, place_type])

  const layout = React.useMemo(() => {
    if (
      !select_card_tags ||
      !Array.isArray(select_card_tags) ||
      select_card_tags.length === 0
    ) {
      return null
    }

    if (!mdUp && !carousel_on_mobile) {
      return <VerticalStackView data={select_card_tags} card={layoutCard} />
    }

    if (
      select_card_tags.length > 3 ||
      (select_card_tags.length === 3 &&
        !lgUp &&
        card_alignment === CARD_ALIGNMENT.VERTICAL) ||
      (!mdUp && carousel_on_mobile)
    ) {
      return (
        <CarouselView
          data={select_card_tags}
          cardAlignment={card_alignment}
          card={layoutCard}
        />
      )
    }

    return (
      <GridView
        data={select_card_tags}
        cardAlignment={card_alignment}
        card={layoutCard}
      />
    )
  }, [select_card_tags, card_alignment, xlUp, lgUp, mdUp])

  return (
    <div
      className={`${classes.container} module`}
      id={section_id}
      data-id="section"
    >
      <div className={classes.frame1}>
        <Stack spacing={2}>
          <Typography className={classes.heading} component="h2">
            {heading}
          </Typography>
          <Typography className={classes.subheading}>{subheading}</Typography>
        </Stack>
        {cta && (
          <Button
            className={classes.button}
            variant="text"
            component="a"
            href={cta.url || '#'}
            onClick={(e) => {
              e.preventDefault()
              cardActionClickHandler(cta)
            }}
            endIcon={
              isUrlExternal(cta?.url) ? (
                <TextButtonExternalLink />
              ) : (
                <ArrowRight />
              )
            }
          >
            {cta.title}
          </Button>
        )}
      </div>
      {layout}
    </div>
  )
}

CardCarousel.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.string,
    section_id: PropTypes.string,
    card_carousel: PropTypes.shape({
      background_color: PropTypes.oneOf([
        COLOR_THEME.CRISSY_FIELD,
        COLOR_THEME.THE_BAKER_BEACH,
        COLOR_THEME.BACKGROUND,
      ]),
      carousel_on_mobile: PropTypes.bool,
      card_alignment: PropTypes.oneOf([
        CARD_ALIGNMENT.VERTICAL,
        CARD_ALIGNMENT.HORIZONTAL,
      ]),
      heading: PropTypes.string,
      subheading: PropTypes.string,
      cta: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
        target: PropTypes.oneOf(['', '0', '_blank']),
      }),
      select_card_tags: PropTypes.array,
    }),
  }),
}
