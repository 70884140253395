import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'

import Button from 'src/common/components/button/Button'
import theme from 'src/styles/theme'
import { CARD_VARIANT } from 'src/common/components/card/constants'
import { DAYS } from 'src/common/constants'

const useStyles = makeStyles()((defaultTheme, props) => ({
  text: {
    ...theme.typography.label,
  },
}))

export default function VerticalStackView(props) {
  const { data, card } = props
  if (!data || !Array.isArray(data) || data.length === 0) return null

  const { classes } = useStyles()

  const [loadedData, setLoadedData] = React.useState([])

  const appendData = () => {
    if (data.length - loadedData.length / 4 > 0) {
      const copy = [
        ...loadedData,
        ...data.slice(loadedData.length, loadedData.length + 4),
      ]

      setLoadedData(copy)
    } else {
      setLoadedData([...data])
    }
  }
  React.useEffect(() => {
    appendData()
  }, [])

  const handleClickLoadMore = (e) => {
    appendData()
  }

  const getDateFromData = (date, type) => {
    const dateFromData = new Date(date * 1000)
    if (type === 'year') {
      return dateFromData?.getFullYear()?.toString()
    }
    if (type === 'day') {
      return dateFromData.getDate().toString()
    }
    if (type === 'month') {
      return dateFromData.toLocaleString('en-US', { month: 'short' })
    }
    if (type === 'dayName') {
      return DAYS[dateFromData.getDay()]
    }

    return ''
  }

  const layoutCard = (cardData, variant, gaTag) =>
    React.cloneElement(card, {
      variant: variant,
      cardData: {
        title: cardData?.Heading,
        description: cardData?.Sub_heading,
        image: {
          url: cardData?.image?.url,
          alt: cardData?.image?.alt,
        },
        link: {
          url: cardData?.card_url,
          title: cardData?.default_text,
        },
        date: {
          year: getDateFromData(
            cardData?.post_date
              ? cardData?.post_date
              : cardData?.event_start_date,
            'year'
          ),
          day: getDateFromData(
            cardData?.post_date
              ? cardData?.post_date
              : cardData?.event_start_date,
            'day'
          ),
          month: getDateFromData(
            cardData?.post_date
              ? cardData?.post_date
              : cardData?.event_start_date,
            'month'
          ),
          weekDay: getDateFromData(cardData?.event_start_date, 'dayName'),
          time: cardData?.event_start_time,
        },
        iconCalender: cardData?.recurring_event,
        trail: {
          length:
            cardData?.trail_length_mile && cardData?.trail_length_km
              ? `${cardData?.trail_length_mile} mile (${cardData?.trail_length_km} km)`
              : undefined,
          activityLevel: cardData?.activity_level,
        },
      },
      gaTag,
    })

  return (
    <div>
      <Stack spacing={3}>
        {loadedData.map((item, i) => (
          <Box width="100%" key={i}>
            {layoutCard(item, CARD_VARIANT.VERTICAL_WIDE, `carousel_${i + 1}`)}
          </Box>
        ))}

        <Stack spacing={2.5} alignItems="center">
          <Typography
            className={classes.text}
          >{`YOU'VE VIEWED: 1-${loadedData.length} of ${data.length}`}</Typography>

          {loadedData.length < data.length && (
            <Button fullWidth variant="secondary" onClick={handleClickLoadMore}>
              LOAD MORE
            </Button>
          )}
        </Stack>
      </Stack>
    </div>
  )
}

VerticalStackView.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      default_text: PropTypes.string,
      card_url: PropTypes.string,
      Heading: PropTypes.string,
      Sub_heading: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    })
  ),
}
