import { Box, Stack, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

import Carousel from 'src/common/components/carousel/Carousel'
import FloatingActionButton from 'src/common/components/floating-action-button/FloatingActionButton'
import { CARD_VARIANT } from 'src/common/components/card/constants'
import { CARD_ALIGNMENT } from 'src/components/card-carousel/constants'
import { CAROUSEL_TYPE } from 'src/common/components/carousel/constants'
import ArrowLeft from 'src/components/icons/ArrowLeft'
import ArrowRight from 'src/components/icons/ArrowRight'
import theme from 'src/styles/theme'

export default function CarouselView(props) {
  const { data, cardAlignment, card } = props
  let touchstartX
  let touchendX
  let touchstartY
  let touchendY
  if (!data || !Array.isArray(data) || data.length === 0) return null

  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'))
  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))
  const xlUp = useMediaQuery(() => theme.breakpoints.up('xl'))

  const [activeIndex, setActiveIndex] = React.useState(0)
  const [disableLeft, setDisableLeft] = React.useState(false)
  const [disableRight, setDisableRight] = React.useState(false)

  function handleGesture() {
    if (
      touchendX < touchstartX &&
      touchstartX - touchendX > 80 &&
      Math.abs(touchstartY - touchendY) < 20
    ) {
      if (!disableRight) setActiveIndex((index) => index + 1)
    }
    if (
      touchendX > touchstartX &&
      touchendX - touchstartX > 80 &&
      Math.abs(touchendY - touchstartY) < 20
    ) {
      if (!disableLeft) setActiveIndex((index) => index - 1)
    }
  }
  const variant = React.useMemo(() => {
    if (xlUp) {
      return CAROUSEL_TYPE.SECONDARY
    }

    if (lgUp || mdUp) {
      if (cardAlignment !== CARD_ALIGNMENT.VERTICAL) {
        return CAROUSEL_TYPE.SECONDARY
      }
    }

    return CAROUSEL_TYPE.PRIMARY
  }, [xlUp, lgUp, mdUp])

  const columns = React.useMemo(() => {
    if (cardAlignment === CARD_ALIGNMENT.VERTICAL) {
      return 4
    }

    if (xlUp) return 2
    return 1
  }, [cardAlignment, xlUp, mdUp, lgUp])

  const getDateFromData = (date, type) => {
    const dateFromData = new Date(date * 1000)
    if (type === 'year') {
      return dateFromData?.getFullYear()?.toString()
    }
    if (type === 'day') {
      return dateFromData?.getDate()?.toString()
    }
    if (type === 'month') {
      return dateFromData.toLocaleString('en-US', { month: 'short' })
    }
    if (type === 'dayName') {
      const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]
      return days[dateFromData.getDay()]
    }

    return ''
  }

  const layoutCard = (cardData, variantCard, gaTag) =>
    React.cloneElement(card, {
      variant: variantCard,
      cardData: {
        title: cardData?.Heading,
        description: cardData?.Sub_heading,
        image: {
          url: cardData?.image?.url,
          alt: cardData?.image?.alt,
        },
        link: {
          url: cardData?.card_url,
          title: cardData?.default_text,
        },
        date: {
          year: getDateFromData(
            cardData?.post_date
              ? cardData?.post_date
              : cardData?.event_start_date,
            'year'
          ),
          day: getDateFromData(
            cardData?.post_date
              ? cardData?.post_date
              : cardData?.event_start_date,
            'day'
          ),
          month: getDateFromData(
            cardData?.post_date
              ? cardData?.post_date
              : cardData?.event_start_date,
            'month'
          ),
          weekDay: getDateFromData(cardData?.event_start_date, 'dayName'),
          time: cardData?.event_start_time,
        },
        iconCalender: cardData?.recurring_event,
        trail: {
          length:
            cardData?.trail_length_mile && cardData?.trail_length_km
              ? `${cardData?.trail_length_mile} mile (${cardData?.trail_length_km} km)`
              : undefined,
          activityLevel: cardData?.activity_level,
        },
      },
      gaTag,
    })

  const list = React.useMemo(() => {
    if (xlUp) {
      const arr = []
      const chunkSize = 2

      for (let i = 0; i < data.length; i += chunkSize) {
        const chunk = data.slice(i, i + chunkSize)
        arr.push(chunk)
      }

      if (cardAlignment === CARD_ALIGNMENT.VERTICAL) {
        return data.map((item, i) => (
          <Box
            onTouchStart={(e) => {
              touchstartX = e.changedTouches[0].screenX
              touchstartY = e.changedTouches[0].screenY
            }}
            onTouchEnd={(e) => {
              touchendX = e.changedTouches[0].screenX
              touchendY = e.changedTouches[0].screenY
              handleGesture()
            }}
            key={i}
            display="flex"
            alignItems="stretch"
          >
            {layoutCard(item, CARD_VARIANT.VERTICAL, `carousel_${i + 1}`)}
          </Box>
        ))
      }

      return arr.map((chunk, i) => (
        <Stack spacing={2}>
          {chunk.map((item, j) => (
            <Box key={`${i}_${j}`}>
              {layoutCard(
                item,
                CARD_VARIANT.HORIZONTAL,
                `carousel_${i * 2 + j + 1}`
              )}
            </Box>
          ))}
        </Stack>
      ))
    }

    if (lgUp || mdUp) {
      if (cardAlignment === CARD_ALIGNMENT.VERTICAL) {
        return data.map((item, i) => (
          <Box
            onTouchStart={(e) => {
              touchstartX = e.changedTouches[0].screenX
              touchstartY = e.changedTouches[0].screenY
            }}
            onTouchEnd={(e) => {
              touchendX = e.changedTouches[0].screenX
              touchendY = e.changedTouches[0].screenY
              handleGesture()
            }}
            width={lgUp ? '25vw' : '40vw'}
            key={i}
            display="flex"
            alignItems="stretch"
          >
            {layoutCard(item, CARD_VARIANT.VERTICAL, `carousel_${i + 1}`)}
          </Box>
        ))
      }

      const arr = []
      const chunkSize = 4

      for (let i = 0; i < data.length; i += chunkSize) {
        const chunk = data.slice(i, i + chunkSize)
        arr.push(chunk)
      }
      return arr.map((chunk, i) => (
        <Stack spacing={2} key={i}>
          {chunk.map((item, j) => (
            <Box key={`${i}_${j}`}>
              {layoutCard(
                item,
                CARD_VARIANT.HORIZONTAL,
                `carousel_${i * 4 + j + 1}`
              )}
            </Box>
          ))}
        </Stack>
      ))
    }

    return data.map((item, i) => (
      <Box
        onTouchStart={(e) => {
          touchstartX = e.changedTouches[0].screenX
          touchstartY = e.changedTouches[0].screenY
        }}
        onTouchEnd={(e) => {
          touchendX = e.changedTouches[0].screenX
          touchendY = e.changedTouches[0].screenY
          handleGesture()
        }}
        width="80vw"
        key={i}
        display="flex"
        alignItems="stretch"
      >
        {layoutCard(item, CARD_VARIANT.VERTICAL, `carousel_${i + 1}`)}
      </Box>
    ))
  }, [mdUp, lgUp, xlUp, activeIndex, disableLeft, disableRight])

  return (
    <>
      <Carousel
        activeIndex={activeIndex}
        spacing={20}
        movableLeft={(v) => setDisableLeft(!v)}
        movableRight={(v) => setDisableRight(!v)}
        variant={variant}
        columns={columns}
      >
        {list}
      </Carousel>

      {(!disableLeft || !disableRight) && (
        <Stack alignItems="flex-end">
          <Stack direction="row" spacing={2}>
            <FloatingActionButton
              title="Previous"
              aria-label="Previous"
              onClick={(e) => setActiveIndex((index) => index - 1)}
              disabled={disableLeft}
              data-ga-location="cardcarousel_left"
            >
              <ArrowLeft />
            </FloatingActionButton>
            <FloatingActionButton
              title="Next"
              aria-label="Next"
              onClick={(e) => setActiveIndex((index) => index + 1)}
              disabled={disableRight}
              data-ga-location="cardcarousel-right"
            >
              <ArrowRight />
            </FloatingActionButton>
          </Stack>
        </Stack>
      )}
    </>
  )
}

CarouselView.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      default_text: PropTypes.string,
      card_url: PropTypes.string,
      Heading: PropTypes.string,
      Sub_heading: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    })
  ),
}
