import { Grid, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

import { CARD_VARIANT } from 'src/common/components/card/constants'
import { DAYS } from 'src/common/constants'
import { CARD_ALIGNMENT } from 'src/components/card-carousel/constants'
import theme from 'src/styles/theme'

export default function GridView(props) {
  const { data, cardAlignment, card } = props
  if (!data || !Array.isArray(data) || data.length === 0) return null

  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))
  const xlUp = useMediaQuery(() => theme.breakpoints.up('xl'))

  const getDateFromData = (date, type) => {
    const dateFromData = new Date(date * 1000)
    if (type === 'year') {
      return dateFromData?.getFullYear()?.toString()
    }
    if (type === 'day') {
      return dateFromData.getDate().toString()
    }
    if (type === 'month') {
      return dateFromData.toLocaleString('en-US', { month: 'short' })
    }
    if (type === 'dayName') {
      return DAYS[dateFromData.getDay()]
    }

    return ''
  }

  const layoutCard = (cardData, variant, gaTag) =>
    React.cloneElement(card, {
      variant: variant,
      cardData: {
        title: cardData?.Heading,
        description: cardData?.Sub_heading,
        image: {
          url: cardData?.image?.url,
          alt: cardData?.image?.alt,
        },
        link: {
          url: cardData?.card_url,
          title: cardData?.default_text,
        },
        date: {
          year: getDateFromData(
            cardData?.post_date
              ? cardData?.post_date
              : cardData?.event_start_date,
            'year'
          ),
          day: getDateFromData(
            cardData?.post_date
              ? cardData?.post_date
              : cardData?.event_start_date,
            'day'
          ),
          month: getDateFromData(
            cardData?.post_date
              ? cardData?.post_date
              : cardData?.event_start_date,
            'month'
          ),
          weekDay: getDateFromData(cardData?.event_start_date, 'dayName'),
          time: cardData?.event_start_time,
        },
        iconCalender: cardData?.recurring_event,
        trail: {
          length:
            cardData?.trail_length_mile && cardData?.trail_length_km
              ? `${cardData?.trail_length_mile} mile (${cardData?.trail_length_km} km)`
              : undefined,
          activityLevel: cardData?.activity_level,
        },
      },
      gaTag,
    })

  const layoutGrid = () => {
    if (
      !xlUp &&
      (cardAlignment === CARD_ALIGNMENT.HORIZONTAL || data.length === 1)
    ) {
      return data.map((item, i) => (
        <Grid item xs={12} key={i}>
          {layoutCard(item, CARD_VARIANT.HORIZONTAL, `carousel_${i + 1}`)}
        </Grid>
      ))
    }

    if (xlUp) {
      if (cardAlignment === CARD_ALIGNMENT.VERTICAL) {
        if (data.length === 3) {
          return data.map((item, i) => (
            <Grid item md={4} key={i}>
              {layoutCard(
                item,
                CARD_VARIANT.VERTICAL_WIDE,
                `carousel_${i + 1}`
              )}
            </Grid>
          ))
        }

        return data.map((item, i) => (
          <Grid item md={6} key={i}>
            {layoutCard(item, CARD_VARIANT.FEATURE, `carousel_${i + 1}`)}
          </Grid>
        ))
      }

      return data.map((item, i) => (
        <Grid item md={6} key={i}>
          {layoutCard(item, CARD_VARIANT.HORIZONTAL, `carousel_${i + 1}`)}
        </Grid>
      ))
    }

    if (lgUp) {
      if (cardAlignment === CARD_ALIGNMENT.VERTICAL) {
        if (data.length === 3) {
          return data.map((item, i) => (
            <Grid item md={4} key={i}>
              {layoutCard(item, CARD_VARIANT.VERTICAL, `carousel_${i + 1}`)}
            </Grid>
          ))
        }
      }
    }

    return data.map((item, i) => (
      <Grid item md={6} key={i}>
        {layoutCard(item, CARD_VARIANT.VERTICAL_WIDE, `carousel_${i + 1}`)}
      </Grid>
    ))
  }

  return (
    <Grid container spacing={2}>
      {layoutGrid()}
    </Grid>
  )
}

GridView.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      default_text: PropTypes.string,
      card_url: PropTypes.string,
      Heading: PropTypes.string,
      Sub_heading: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    })
  ),
}
